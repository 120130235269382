import axios from 'axios'
import { get, save } from "../utils";
import Config from "../config";
import { createUrlParams } from '../utils';
import {toast} from "react-toastify";


let instance = axios.create({
  baseURL: 'https://api.rubbl.com.au/api/',
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

let instance1 = axios.create({
  baseURL: 'https://api.rubbl.com.au/api/',
  headers: {
    "Content-Type": "multipart/form-data",
  },
  timeout: 10000,
});

let instance2 = axios.create({
  baseURL: 'https://api.rubbl.com.au/api/',
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

let noLimitInstance = axios.create({
  baseURL: 'https://api.rubbl.com.au/api/',
  headers: {
    "Content-Type": "multipart/form-data",
  },
});


noLimitInstance.interceptors.request.use(function (config) {
  const token = get("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
instance.interceptors.request.use(function (config) {
  const token = get("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance1.interceptors.request.use(function (config) {
  const token = get("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance2.interceptors.request.use(function (config) {
  const token = get("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});


const API_URL = process.env.REACT_APP_API_URL
// const API = {
//   login: (payload) => {
//     return axios
//       .post(`http://44.226.238.61/api/admin/login`, payload)
//       .then((res) => {
//         console.log('res', res)
//         return res
//       })
//       .catch((err) => {
//         return err.response
//       })
//   },
const API = {
  login: async (data) => {
    return instance
      .post(Config.USER_LOGIN, data)
      .then((res) => {
        if (res && res.data) {
          save("token", res?.data?.data?.accessToken);
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  accountList: async (data) => {
    return instance
        .get(`${Config.ACCOUNT_LIST}?${createUrlParams(data)}`)
        .then(res => { 
          // console.log("res",res);
          return res 
        })
        .catch(e => { return e?.response });
  },

  supplierList: async (data) => {
    return instance
        .get(`${Config.SUPPLIER_LIST}?${createUrlParams(data)}`)
        .then(res => { 
          // console.log("res77",res);
          return res 
        })
        .catch(e => { return e?.response });
  },
  transporterList: async (data) => {
    return instance
    .get(`${Config.SUPPLIER_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  ordersList: async (data) => {
    return instance
    .get(`${Config.ORDERS_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      //  console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  emailTemplateList: async (data) => {
    return instance
    .get(`${Config.EMAIL_TEMPLATE}?${createUrlParams(data)}`)
    .then(res => { 
      //  console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  materialList: async (data) => {
    return instance
    .get(`${Config.MATERIAL_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      //  console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  notificationTemplate: async (data) => {
    return instance
    .get(`${Config.NOTIFICATION_TEMPLATE}?${createUrlParams(data)}`)
    .then(res => { 
      //  console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  invoiceList: async (data) => {
    return instance
    .get(`${Config.INVOICE_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      //  console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  addContractor: async (data) => {
    return instance
    .post(Config.ADD_CONTRACTOR, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateContractor: async (data) => {
    return instance
    .patch(`${Config.UPDATE_CONTRACTOR}/${data?.accountId}`, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  addSupplier: async (data) => {
    return instance
    .post(Config.ADD_SUPPLIER, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateSupplier: async (data) => {
    return instance
    .patch(`${Config.UPDATE_SUPPLIER}/${data?.accountId}`, data)
    .then(res => { 
      console.log("res89",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  imageUpload: async (data) => {
    // console.log("data",data);
    return instance1
    .post(Config.UPLOAD_IMAGE, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  addTransporter: async (data) => {
    return instance
    .post(Config.ADD_TRANSPORTER, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateTransporter: async (data) => {
    return instance
    .patch(`${Config.UPDATE_TRANSPORTER}/${data?.accountId}`, data)
    .then(res => { 
      // console.log("res8910",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  activeSites: async (data) => {
    return instance
    .get(`${Config.ACTIVE_SITES}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  contractorOrderList: async (data) => {
    return instance
    .get(`${Config.CONTRACTOR_ORDER_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  contractorInvoiceList: async (data) => {
    return instance
    .get(`${Config.CONTRACTOR_INVOICE_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  supplierOrderList: async (data) => {
    return instance
    .get(`${Config.SUPPLIER_ORDER_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  supplierOrderDetail: async (data) => {
    return instance
    .get(`${Config.SUPPLIER_ORDER_DETAIL}/${data?.order_id}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  supplierMaterialList: async (data) => {
    return instance
    .get(`${Config.SUPPLIER_MATERIAL_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  transporterOrderList: async (data) => {
    return instance
    .get(`${Config.TRANSPORTER_ORDER_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  driversList: async (data) => {
    return instance
    .get(`${Config.DRIVERS_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  vehicleList: async (data) => {
    return instance
    .get(`${Config.VEHICLE_LIST}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  helpCenter: async (data) => {
    return instance
    .get(`${Config.HELP_CENTER}?${createUrlParams(data)}`)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  deleteHelpCenter: async (data) => {
    return instance
    .delete(`${Config.DELETE_HELP_CENTER}/${data?.id}`, data)
    .then(res => { 
      console.log("res8910",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  materialCategory: async (data) => {
    return instance
    .get(`${Config.MATERIAL_CATEGORY}?${createUrlParams(data)}`)
    .then(res => { 
      return res
    })
    .catch(e => { return e?.response });
  },
  addMaterial: async (data) => {
    console.log("data",data);
    return instance
    .post(Config.ADD_MATERIAL, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateMaterial: async (data) => {
    return instance
    .patch(`${Config.UPDATE_MATERIAL}/${data?.materialId}`, data)
    .then(res => { 
      // console.log("res8910",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  vehicleType: async (data) => {
    return instance
    .get(`${Config.VEHICLE_TYPE}?${createUrlParams(data)}`)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  tieredPricing: async (data) => {
    return instance
    .post(Config.TIERED_PRICING, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  dashboardCount: async (data) => {
    return instance
    .get(Config.DASHBOARD_COUNT, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  chartData: async (data) => {
    return instance
    .get(Config.CHART_DATA, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  addEmailTemplate: async (data) => {
    return instance
    .post(Config.ADD_EMAIL_TEMPLATE, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateEmailTemplate: async (data) => {
    return instance
    .patch(`${Config.UPDATE_EMAIL_TEMPLATE}/${data?.emailTemplateId}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateNotificationTemplate: async (data) => {
    return instance
    .patch(`${Config.EDIT_NOTIFICATION_TEMPLATE}/${data?.templateId}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateInvoice: async (data) => {
    return instance
    .patch(`${Config.UPDATE_INVOICE}/${data?.invoiceId}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  sendReceipt: async (data) => {
    return instance
    .get(`${Config.SEND_RECEIPT}?${createUrlParams(data)}`)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  editSupplierMaterial: async (data) => {
    return instance
    .post(Config.EDIT_SUPPLIER_MATERIAL, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  deleteMaterial: async (data) => {
    return instance
    .delete(`${Config.DELETE_MATERIAL}/${data?.id}`)
    .then(res => { 
      console.log("res8910",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  orderCancel: async (data) => {
    return instance
    .post(`${Config.ORDER_CANCEL}/${data?.orderId}?${createUrlParams(data)}`)
    .then(res => { 
      console.log("res8910",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  forgotPassword: async (data) => {
    return instance
    .post(Config.FORGOT_PASSWORD, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  getTermsAndCondition: async (data) => {
    return instance
    .get(`${Config.GET_TERMS_CONDITIONS}/${data?.slug}`)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateTermsAndCondition: async (data) => {
    return instance
    .patch(`${Config.UPDATE_TERMS_CONDITIONS}/${data?.id}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  getPrivacyPolicy: async (data) => {
    return instance
    .get(`${Config.GET_PRIVACY_POLICY}/${data?.slug}`)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updatePrivacyPolicy: async (data) => {
    return instance
    .patch(`${Config.UPDATE_PRIVACY_POLICY}/${data?.id}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  getAbout: async (data) => {
    return instance
    .get(`${Config.GET_ABOUT}/${data?.slug}`)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateAbout: async (data) => {
    return instance
    .patch(`${Config.UPDATE_ABOUT}/${data?.id}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  invoiceDownload: async (data) => {
    const token = get("token");
    const url = `${API_URL}download_invoice?${createUrlParams(data)}`;
    const filename = `Rubbl_Tax_invoice_${data.name}.pdf`;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      return blob;
    });
    // return instance2
    // .get(`${Config.INVOICE_DOWNLOAD}?${createUrlParams(data)}`)
    // .then(res => { 
      
      
    //   return res 
    // })
    // .catch(e => { return e?.response });
},
  getTieredPricing: async (data) => {
    return instance
    .get(Config.GET_TIERED_PRICING, data)
    .then(res => { 
      console.log("res77",res);
      return res
    })
    .catch(e => { return e?.response });
  },
  supplierPayments: async (data) => {
    return instance
    .get(`${Config.SUPPLIER_PAYMENTS}?${createUrlParams(data)}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateSupplierPayments: async (data) => {
    return instance
    .patch(`${Config.UPDATE_SUPPLIER_PAYMENTS}/${data?.paymentId}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  transporterPayments: async (data) => {
    return instance
    .get(`${Config.TRANSPORTER_PAYMENTS}?${createUrlParams(data)}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  updateTransporterPayments: async (data) => {
    return instance
    .patch(`${Config.UPDATE_TRANSPORTER_PAYMENTS}/${data?.paymentId}`, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  getLoadAssumptions: async (data) => {
    return instance
    .get(Config.GET_LOAD_ASSUMPTIONS, data)
    .then(res => { 
      console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  changePassword: async (data) => {
    return instance
    .post(Config.CHANGE_PASSWORD, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  cancelOrder: async (data) => {
    return instance
    .post(`${Config.CANCEL_ORDER}/${data?.orderId}`, data)
    .then(res => { 
      // console.log("res77",res);
      return res 
    })
    .catch(e => { return e?.response });
  },
  createAbout: async (data) => {
    return instance
    .post(`${Config.POST_PAGES_CREATE}`, data)
    .then(res => {
      return res
    })
    .catch(e => { return e?.response });
  },
  updateRubbishAbout: async (data) => {
    return instance
        .patch(`${Config.UPDATE_RUBBISH_ABOUT}/${data?.id}`, data)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  getRubblData: async (data) => {
    return instance
        .get(`${Config.GET_RUBBL_DATA}/${data?.slug}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  productsLists: async () => {
    return instance
        .get(`${Config.PRODUCTS_LIST}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  greenWasteLists: async () => {
    return instance
        .get(`${Config.GREEN_WASTE_LIST}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  assortmentLists: async () => {
    return instance
        .get(`${Config.ASSORTMENT_LIST}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  equivalentsIndex: async () => {
    return instance
        .get(`${Config.EQUIVALENTS_INDEX_LIST}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  createEquivalentsStore: async (data) => {
    return new Promise((resolve, reject)=> {
      return noLimitInstance
          .post(Config.CREATE_EQUIVALENTS_STORE, data)
          .then(res => {
            toast.success(res?.data?.message);
            resolve(res);
            return res;
          })
          .catch(err => {
            (err.response?.data?.error || []).forEach((message) => toast.error(message));
            reject(err?.response);
            return err?.response;
          });
    })
  },
  updateEquivalents: async (data) => {
    return new Promise((resolve, reject)=> {
      return noLimitInstance
          .post(`${Config.CREATE_EQUIVALENTS_UPDATE}/${data.id}`, data)
          .then(res => {
            toast.success(res?.data?.message);
            resolve(res);
            return res;
          })
          .catch(err => {
            (err.response?.data?.error || []).forEach((message) => toast.error(message));
            reject(err?.response);
            return err?.response;
          });
    })
  },
  deleteEquivalents: async (id) => {
    return instance
        .delete(`${Config.DELETE_EQUIVALENTS}/${id}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  createProduct: async (data) => {
    return new Promise((resolve, reject)=> {
      return noLimitInstance
          .post(Config.CREATE_PRODUCTS, data)
          .then(res => {
            toast.success(res?.data?.message);
            resolve(res);
            return res;
          })
          .catch(err => {
            (err.response?.data?.error || []).forEach((message) => toast.error(message));
            reject(err?.response);
            return err?.response;
          });
    })
  },
  updateProduct: async (data) => {
    return new Promise((resolve, reject)=> {
      return noLimitInstance
          .post(`${Config.CREATE_PRODUCTS}/${data.id}`, data)
          .then(res => {
            toast.success(res?.data?.message);
            resolve(res);
            return res;
          })
          .catch(err => {
            (err.response?.data?.error || []).forEach((message) => toast.error(message));
            reject(err?.response);
            return err?.response;
          });
    })
  },
  deleteProduct: async (id) => {
    return instance
        .delete(`${Config.CREATE_PRODUCTS}/${id}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
  deleteGreenWasteProduct: async (id) => {
    return instance
        .delete(`${Config.CREATE_PRODUCTS}/${id}`)
        .then(res => {
          return res
        })
        .catch(e => { return e?.response });
  },
}
export default API
